export default function copiarObj(original: Record<string, any>) {
  const copia: any = original instanceof Array ? [] : {};
  // eslint-disable-next-line no-restricted-syntax
  for (const i in original) {
    if (original[i] && typeof original[i] === 'object') copia[i] = copiarObj(original[i]);
    else copia[i] = original[i];
  }

  return copia;
}
