/* eslint-disable no-unused-expressions */
import { Vue, Component } from 'vue-property-decorator';
import { format } from 'date-fns';
import router from '@/router';
import store from '@/store';
import PlannerService from '@/services/Planner/PlannerService';

let contextID: number | null = null;
const FORMAT_DATE = 'MM/dd/yyyy';

@Component
export default class PlannerData extends Vue {
  protected currentParentTopicTitle = '';
  private topicPlanner: Record<string, any> = {};
  protected currentWeekPlanner: Array<Record<string, any>> = [];
  protected PlannerService = new PlannerService();

  async setTopicPlanner() {
    try {
      const idTopic = router.currentRoute.params.topicID;
      const indexGrid = router.currentRoute.params.path;

      const response = await this.PlannerService.getPlanner();

      if (response?.weeks && response?.weeks.length) {
        const currentWeekfilter = this.filterCurrentWeek(response?.weeks);

        const currentWeekFiles = this.dataProcessingPlanner(
          currentWeekfilter[0]?.grid[Number(indexGrid)]
        );

        await store.dispatch('loaderData', {
          index: Number(indexGrid),
          week: currentWeekfilter[0]?.grid && currentWeekfilter[0]?.grid[Number(indexGrid)]
        });

        this.currentWeekPlanner = this.addFilesGridPlanner(currentWeekFiles);
        const topic = this.searchTopicPlanner(this.currentWeekPlanner, Number(idTopic));

        return {
          topic,
          contextID
        };
      }
      throw new Error('');
    } catch (error) {
      console.error(error);
      throw new Error('');
    }
  }

  filterCurrentWeek(listWeek: Array<Record<string, any>>) {
    const currentDate = format(new Date(), FORMAT_DATE);

    const currentClass = listWeek.filter((classWeek: Record<string, any>) => {
      const initDate = format(new Date(classWeek?.init), FORMAT_DATE);
      const endDate = format(new Date(classWeek?.end), FORMAT_DATE);
      return initDate <= currentDate && endDate >= currentDate;
    });

    return currentClass;
  }

  dataProcessingPlanner(week: Array<Record<string, any>>) {
    const newWeek: Array<Record<string, any>> = [];

    // eslint-disable-line
    week?.forEach((discipline: Record<string, any>, indexDiscipline: number) => {
      newWeek[indexDiscipline] = this.dataProcessingPlannerTopic(week[indexDiscipline]);
    });

    return newWeek;
  }

  dataProcessingPlannerTopic(topic: Record<string, any>) {
    const newTopic = topic;
    if (newTopic?.topicId) {
      const topicID = newTopic.topicId;
      const videos = newTopic.videoTeoric;

      newTopic.idTopic = topicID;
      newTopic.videos = videos;

      delete newTopic.topicId;
      delete newTopic.videoTeoric;
    }

    if (newTopic.topics && newTopic.topics.length > 0) {
      newTopic.topics.forEach((topicItem: Record<string, any>) => {
        this.dataProcessingPlannerTopic(topicItem);
      });
    }

    return newTopic;
  }

  addFilesGridPlanner(week: Array<Record<string, any>>) {
    const newWeek: Array<Record<string, any>> = [];

    week.forEach((discipline: Record<string, any>, indexDiscipline: number) => {
      newWeek[indexDiscipline] = this.setFilesGridPlanner(week[indexDiscipline]);
    });

    return newWeek;
  }

  setFilesGridPlanner(topic: Record<string, any>) {
    const newTopic = topic;

    if (newTopic?.videos && newTopic?.videos.length) {
      const videos = store.getters.plannerAccordionFiles?.videos;

      const listFileType = newTopic?.videos.map((video: Record<string, any>) => video.idVideo);

      const listFiles: Array<Record<string, any>> = [];

      listFileType.forEach((idVideo: Record<string, any>) => {
        const found = videos.find(
          (videoItem: Record<string, any>) => videoItem.idVideo === idVideo
        );
        if (found) listFiles.push(found);
      });

      newTopic.videos = listFiles;
    }

    if (newTopic?.archives && newTopic?.archives.length) {
      const archives = store.getters.plannerAccordionFiles?.archives;

      const listFileType = newTopic?.archives.map(
        (archive: Record<string, any>) => archive.idArchive
      );

      const listFiles: Array<Record<string, any>> = [];

      listFileType.forEach((idArchive: Record<string, any>) => {
        const found = archives.find(
          (archiveItem: Record<string, any>) => archiveItem.idArchive === idArchive
        );
        if (found) listFiles.push(found);
      });

      newTopic.archives = listFiles;
    }

    // Completar para exercicios...

    if (newTopic.topics && newTopic.topics.length > 0) {
      newTopic.topics.forEach((topicItem: Record<string, any>) => {
        this.setFilesGridPlanner(topicItem);
      });
    }

    return newTopic;
  }

  searchTopicPlanner(week: Array<Record<string, any>>, idTopic: number) {
    week.every((discipline: Record<string, any>, index: number) => {
      const foundDiscipline = this.getTopicPlanner(discipline, idTopic);

      if (foundDiscipline) {
        this.currentParentTopicTitle = week[index]?.title || '';
        contextID = discipline.id;
        return false;
      }
      return true;
    });
    return this.topicPlanner;
  }

  getTopicPlanner(discipline: Record<string, any>, idTopic: number) {
    let foundTopic = false;
    if (discipline?.idTopic && discipline?.idTopic === idTopic) {
      this.topicPlanner = discipline;
      foundTopic = true;
    }
    if (discipline?.topics && discipline?.topics.length > 0) {
      discipline.topics.every((topic: Record<string, any>) => {
        foundTopic = this.getTopicPlanner(topic, idTopic);
        return !foundTopic;
      });
    }
    return foundTopic;
  }
}
